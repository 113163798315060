import React, { useState, useEffect } from 'react';

import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';
import LeafletgeoSearch from './LeafletgeoSearch';
import MapControls from './MapControls';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import './font.css';

import 'leaflet-geosearch/dist/geosearch.css';

const position = [30.33084, 71.2475];

export const AdvisoryModal = NiceModal.create(({ location }) => {
  const modal = useModal();
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const handleFormSubmit = (condition) => {
    if (condition) {
      setMessage('Message sent successfully');
      setSeverity('success');
    } else {
      setMessage('Message sent failed. Please try again after 1 minute');
      setSeverity('error');
    }
    setFormSubmitted(true);
  };
  useEffect(() => {
    if (isFormSubmitted) {
      const timeout = setTimeout(() => {
        setFormSubmitted(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [isFormSubmitted]);

  console.log('KML', location);
  return <></>;
});

const MapView = () => {
  const steps1 = [];

  const steps = [];

  const [isTourOpen, setIsTourOpen] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {console.log(123)}, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email:', email);
    console.log('Password:', password);
  };

  return (
    <>
      {/* <Title title={'Advisory Service'} />
      <div
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          borderRadius: '10px',
          padding: '10px',
          background: '#31313196'
        }}
      >

        <ArrowBackIcon
          style={{
            color: 'white',
            fontSize: '32px',
            marginRight: '5px'
          }}
        />

        <span onClick={goBack} style={{ color: 'white', fontSize: '18px' }}>Go Back</span>
      </div> */}
      <MapContainer
        center={position}
        zoomControl={false}
        zoom={7}
        scrollWheelZoom={true}
        style={{ minHeight: '100vh', maxWidth: '100vw' }}
      >
        <TileLayer
          url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
          maxZoom={20}
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        />
        {/* <ZoomControl position="bottomright" /> */}
        <LeafletgeoSearch className="step-1" />
        <MapControls />
      </MapContainer>
      <div
      // style={{
      //   top: '10px',
      //   left: '10px',
      //   zIndex: 1001,
      //   // width: '100vh',
      //   height: 441
      // }}
      >
        {/* <AdvisoryGraph /> */}
      </div>
      <div></div>
      {/* <Tour
        backdrop={"static"}
        steps={steps}
        onRequestClose={() => setIsTourOpen(false)}
        closeWithMask={false}
        isOpen={isTourOpen} /> */}
    </>
  );
};

export default MapView;
